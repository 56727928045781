@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: white;
}

.title {
  font-size: 30px;
  background: linear-gradient(to right,
      #7953cd 20%,
      #00affa 30%,
      #0190cd 70%,
      #764ada 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  background-size: 500% auto;
  animation: textShine 5s ease-in-out infinite alternate;
}

@keyframes textShine {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}